<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-tabs>
        <b-tab
          v-for="data_user in data"
          :key="'produto-' + data_user.produto"
        >
          <template #title>
            <span>{{ data_user.produto }}</span>
            <span :class="`mx-50 bullet bullet-sm bullet-${data_user.statusAssinatura === 'Ativo' ? 'success' : 'danger'}`" />
          </template>

          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              Plano
            </h5>
            <b-card-text>
              {{ data_user.plano }}
            </b-card-text>
          </div>

          <template v-if="data_user.tempoVariacao!='I'">
            <div class="mt-2">
              <h5 class="text-capitalize mb-75">
                Valor
              </h5>
              <b-card-text v-if="data_user.valor==0">
                Grátis
              </b-card-text>
              <b-card-text v-else>
                R$ {{ data_user.valor }}
              </b-card-text>
            </div>
            <div class="mt-2">
              <h5 class="text-capitalize mb-75">
                Vencimento
              </h5>
              <b-card-text v-if="data_user.dataProximoVencimento">
                {{ formatDateBR(data_user.dataProximoVencimento) }}
              </b-card-text>
              <b-card-text v-else>
                Calculado após o primeiro pagamento
              </b-card-text>
            </div>
          </template>

          <div class="mt-2">
            <h5 class="text-capitalize mb-75">
              Status
            </h5>
            <b-card-text>
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="data_user.statusAssinatura"
                  :name="`status-${data_user.id}`"
                  value="Inativo"
                  class="custom-control-secondary"
                  style="margin-top: 0px"
                  @change="confirmarAlterarStatus(data_user, 'inativação')"
                >
                  Inativo
                </b-form-radio>
                <b-form-radio
                  v-model="data_user.statusAssinatura"
                  :name="`status-${data_user.id}`"
                  value="Ativo"
                  class="custom-control-success"
                  style="margin-top: 0px"
                  @change="confirmarAlterarStatus(data_user, 'ativação')"
                >
                  Ativo
                </b-form-radio>
              </div>
            </b-card-text>
          </div>

          <div
            v-if="data_user.tempoVariacao!='I' && data_user.pagamentos.length>0"
            class="mt-2"
          >
            <h5 class="text-capitalize mb-75">
              Pagamentos
            </h5>
            <b-card-text>
              <b-table-lite
                sticky-header="300px"
                hover
                responsive
                :items="data_user.pagamentos"
                :fields="['status', 'plano', 'dataRecorrencia', 'valor', 'dataVencimento', 'formaPagamento', 'dataPagamento']"
              >
                <template #cell(status)="data">
                  <template v-if="data.value=='Pago'">
                    <b-button
                      disabled
                      variant="flat-success"
                    >
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-50"
                      />
                      <span class="align-middle">{{ data.value }}</span>
                    </b-button>
                  </template>
                  <template v-else>
                    <b-button
                      variant="success"
                      @click.stop="confirmarPagamento(data.item.id)"
                    >
                      <span class="align-middle">Pagar</span>
                    </b-button>
                  </template>
                </template>

                <template #cell(dataRecorrencia)="data">
                  {{ formatDateSemDiaBR(data.value) }}
                </template>

                <template #cell(valor)="data">
                  <template v-if="data.value=='0'">
                    Grátis
                  </template>
                  <template v-else>
                    R$ {{ data.value }}
                  </template>
                </template>

                <template #cell(dataVencimento)="data">
                  {{ formatDateBR(data.value) }}
                </template>

                <template #cell(dataPagamento)="data">
                  {{ formatDateTimeBR(data.value) }}
                </template>
              </b-table-lite>
            </b-card-text>
          </div>
        </b-tab>
      </b-tabs>
    </b-overlay>
  </b-card>
</template>

<script>

import {
  BOverlay, BTabs, BTab, BCardText, BFormRadio, BTableLite, BCard, BButton,
} from 'bootstrap-vue'
import { formatDateTimeBR, formatDateBR, formatDateSemDiaBR } from '@/utils/filter'

export default {

  components: {
    BOverlay,
    BTabs,
    BTab,
    BCardText,
    BFormRadio,
    BTableLite,
    BCard,
    BButton,
  },

  data() {
    return {
      formatDateTimeBR,
      formatDateBR,
      formatDateSemDiaBR,
      loadingOverlay: false,
      data: [],
    }
  },

  created() {
    this.getAssinantePlano(this.$route.params.id)
  },

  methods: {
    getAssinantePlano(id) {
      this.loadingOverlay = true
      this.$store.dispatch('controle-assinante/getAssinantePlano', id)
        .then(res => { this.data = res.data })
        .finally(() => {
          this.loadingOverlay = false
        })
    },

    alterarStatus(data) {
      this.$store.dispatch('controle-assinante/alterarStatus', { id: data.id, status: data.statusAssinatura })
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Sucesso',
            text: 'Assinatura alterada com sucesso',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
    },

    confirmarAlterarStatus(data, texto) {
      /* eslint no-param-reassign: "error" */
      this.$swal({
        title: 'Alteração do status',
        text: `Confirma a ${texto} da assinatura?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmo',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.alterarStatus(data)
        } else {
          if (data.statusAssinatura === 'Ativo') {
            data.statusAssinatura = 'Inativo'
            return
          }
          data.statusAssinatura = 'Ativo'
        }
      })
    },

    recebido(data) {
      this.$store.dispatch('controle-assinante/confirmaPagamento', { id: data })
        .then(() => {
          this.getAssinantePlano(this.$route.params.id)
        })
    },

    confirmarPagamento(data) {
      /* eslint no-param-reassign: "error" */
      this.$swal({
        title: 'Confirmar o pagamento',
        text: 'Confirma o recebimento do pagamento?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirmo',
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.isConfirmed) {
          this.recebido(data)
        }
      })
    },
  },

}
</script>
