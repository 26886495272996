<template>
  <div>
    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <div>
        <h5 class="text-capitalize mb-75">
          Nome
        </h5>
        <b-card-text>
          {{ data.nome }} {{ data.sobrenome }}
        </b-card-text>
      </div>
      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Email
        </h5>
        <b-card-text>
          {{ data.email }}
        </b-card-text>
      </div>
      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Telefone
        </h5>
        <b-card-text>
          {{ data.telefone }}
        </b-card-text>
      </div>
      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Criando em
        </h5>
        <b-card-text>
          {{ formatDateTimeBR(data.dataCriacao) }}
        </b-card-text>
      </div>
      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Último login
        </h5>
        <b-card-text>
          {{ formatDateTimeBR(data.dataUltimoLogin) }}
        </b-card-text>
      </div>
      <div class="mt-2">
        <h5 class="text-capitalize mb-75">
          Origem
        </h5>
        <b-card-text>
          {{ data.origem }}
        </b-card-text>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay, BCardText,
} from 'bootstrap-vue'
import { formatDateTimeBR } from '@/utils/filter'

export default {

  components: {
    BOverlay,
    BCardText,
  },

  data() {
    return {
      formatDateTimeBR,
      loadingOverlay: false,
      data: [],
    }
  },

  created() {
    this.getAssinante(this.$route.params.id)
  },

  methods: {
    getAssinante(id) {
      this.loadingOverlay = true
      this.$store.dispatch('controle-assinante/getAssinante', id)
        .then(res => { this.data = res.data })
        .finally(() => {
          this.loadingOverlay = false
        })
    },
  },
}
</script>
