import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getAssinantes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('controle/assinante', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getAssinante(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.get(`controle/assinante/${id}`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getAssinantePlano(ctx, id) {
      return new Promise((resolve, reject) => {
        axios.get(`controle/assinante/${id}/plano`)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    alterarStatus(ctx, data) {
      return new Promise((resolve, reject) => {
        axios.post('controle/alterar-status', data)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    confirmaPagamento(ctx, playload) {
      return new Promise((resolve, reject) => {
        axios.post('controle/assinante/confirma-pagamento', playload)
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
