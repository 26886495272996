<template>
  <b-tabs>
    <b-tab>
      <template #title>
        <feather-icon icon="UserIcon" />
        <span>Perfil</span>
      </template>
      <b-card>
        <b-row>
          <b-col
            cols="12"
          >
            <assinante-edit-tab-perfil />
          </b-col>
        </b-row>
      </b-card>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="LayersIcon" />
        <span>Assinaturas</span>
      </template>
      <b-card>
        <b-row>
          <b-col
            cols="12"
          >
            <assinante-edit-tab-assinatura />
          </b-col>
        </b-row>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>

import {
  BTabs, BTab, BCard, BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store'
import AssinanteEditTabPerfil from './AssinanteEditTabPerfil.vue'
import AssinanteEditTabAssinatura from './AssinanteEditTabAssinatura.vue'
import assinanteStoreModule from './assinanteStoreModule'

const CONTROLE_ASSINANTE_STORE_MODULE_NAME = 'controle-assinante'

export default {
  components: {
    AssinanteEditTabPerfil,
    AssinanteEditTabAssinatura,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
  },

  created() {
    if (!store.hasModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME)) store.registerModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME, assinanteStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME)) store.unregisterModule(CONTROLE_ASSINANTE_STORE_MODULE_NAME)
  },

}
</script>
